import { fetcher } from 'src/api/fetch';
import { SocialMessenger } from './Messengers.types';

const requestGetSocialMessengers = async (language: string) => {
  const response = await fetcher<SocialMessenger[]>(`/about/contacts`, {
    language,
  });

  return response;
};

export default requestGetSocialMessengers;
