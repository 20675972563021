
import Cookies from 'js-cookie';
import { useSearchParams } from 'next/navigation';
import { Fragment, useEffect } from "react";
import { sendDimensionSet } from "src/utils/enhancedEcommerce";
import { updateAffiliateProgramsCookie } from "../../helpers/affiliatePrograms";

const GaClientWatcher = () => {
  useEffect(() => {
    const gaCookie = Cookies.get('_ga');
    const clientId = gaCookie && gaCookie.slice(6);
    sendDimensionSet(clientId);
  }, []);

  return null;
};

interface Props {
  host?: string;
}

const AffiliateProgramsCookieWatcher = ({ host }: Props) => {
  const searchParams = useSearchParams();
  const query = Object.fromEntries(searchParams?.entries() || []);

  useEffect(() => {
    updateAffiliateProgramsCookie(query, host);
  }, [host, query]);

  return null;
};

const AppCookiesWatcher = (props: Props) => {
  return (
    <Fragment>
      <AffiliateProgramsCookieWatcher host={props.host} />
      <GaClientWatcher />
    </Fragment>
  );
};

export default AppCookiesWatcher;
