import { IS_UKRAINE } from "src/data/constants";
import { RenderAfterInteract } from "../interact-watcher";
import Messengers from "./Messengers";

const MessengersApp = () => {
  if (IS_UKRAINE) {
    return (
      <RenderAfterInteract>
        <Messengers variant="column" />
      </RenderAfterInteract>
    );
  }

  return null;
};

export default MessengersApp;
