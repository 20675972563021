'use client';
import { CROSS_DOMAIN_GTM_ID, GTM_ID } from "src/data/constants";
import { RenderAfterInteract } from "src/modules/interact-watcher";
import { MessengersApp } from "src/modules/messengers";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import OpenGraphMeta from "../../../../components/OpenGraphMeta";
import AppCookiesWatcher from "../AppCookiesWatcher/AppCookiesWatcher";
import AuthSynchronizerWatcher from "../AuthSynchronizerWatcher/AuthSynchronizerWatcher";
import GtmNoScript from "../GtmNoScript/GtmNoScript";
import GtmScript from "../GtmScript/GtmScript";
import dynamic from "next/dynamic";

const ModalsList = dynamic(
  () => import('src/modules/modals/ModalsList'),
  { ssr: false },
);

const GlobalComponents = ({ host }: { host: string }) => {
  const lang = useLang();
  const localize = useLocalize();

  return (
    <>
      <ModalsList />

      <MessengersApp />
      <AuthSynchronizerWatcher />

      <RenderAfterInteract>
        <GtmScript gtmId={GTM_ID} />
        <GtmScript gtmId={CROSS_DOMAIN_GTM_ID} />
        <GtmNoScript gtmId={GTM_ID} />
        <GtmNoScript gtmId={CROSS_DOMAIN_GTM_ID} />
      </RenderAfterInteract>
      {/* @todo: move opengraph to metadata after migrating to app routing */}
      <OpenGraphMeta
        lang={lang}
        title={localize('meta_main_title')}
        description={localize('meta_main_description')}
      />
      <AppCookiesWatcher host={host} />
    </>
  );
};
export default GlobalComponents;
