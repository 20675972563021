import { Fragment, ReactNode } from 'react';
import { useInteractWatcherContext } from './InteractWatcherContext';

interface RenderAfterInteractProps {
  children: ReactNode;
}

const RenderAfterInteract = (props: RenderAfterInteractProps) => {
  const { children } = props;
  const userInteracted = useInteractWatcherContext();

  if (!userInteracted) {
    return null;
  }

  return <Fragment>{ children }</Fragment>;
};

export default RenderAfterInteract;
