'use client';
import { ReactNode } from "react";
import { Category } from "src/api/types";
import { AddressManagementRetailsProvider } from "src/modules/address-management";
import { InteractWatcherProvider } from "src/modules/interact-watcher";
import { MessengersContextProvider } from "src/modules/messengers";
import { ServicesProvider } from "src/services";
import { TranslationDictionary } from "src/services/localize/useLocalizeService";
import { ThemeProvider } from "src/ui-kit/theme";
import { SWRConfig } from "swr";

interface GlobalProvidersProps {
  children: ReactNode;
  language: string;
  translations: TranslationDictionary;
  categories: Category[];
}

const GlobalProviders = (props: GlobalProvidersProps) => {
  const {
    children,
    language,
    translations,
    categories,
  } = props;

  return (
    <ThemeProvider skin='zakaz'>
      <InteractWatcherProvider >
        <SWRConfig value={{ errorRetryCount: 1, dedupingInterval: 600000 }}>
          <ServicesProvider
            initLang={language}
            translations={translations}
            initCategories={categories}
          >
            <MessengersContextProvider>
              <AddressManagementRetailsProvider language={language}>
                {children}
              </AddressManagementRetailsProvider>
            </MessengersContextProvider>
          </ServicesProvider>
        </SWRConfig>
      </InteractWatcherProvider>
    </ThemeProvider>
  );
};
export default GlobalProviders;
