import { useContext } from 'react';
import MessengersContext from './MessengersContext';

const useMessengersContext = () => {
  const ctxValue = useContext(MessengersContext);

  if (ctxValue === null) {
    throw new Error('Using MessengersContext out of provider');
  }

  return ctxValue;
};

export default useMessengersContext;
