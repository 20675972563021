import { MessengerCloseButtonProps } from '../Messengers.types';

const MessengerCloseButton = ({ onClick }: MessengerCloseButtonProps) => {
  return (
    <div className='IconClose' onClick={onClick}>
      <style jsx>{`
      .IconClose {
        position: relative;
        width: 40px;
        height: 40px;
        border: 1px solid #FFCF1B;
        border-radius: 50%;
        background: #fff;
      }
      .IconClose::before,
      .IconClose::after {
        content: '';
        position: absolute;
        left: 9px;
        top: 18px;
        width: 20px;
        height: 1px;
        background-color: #FFCF1B;
        border-radius: 2px;
        transform: rotate(45deg);
      }

      .IconClose::after {
        transform: rotate(-45deg);
      }
      `}</style>
    </div>
  );
};

export default MessengerCloseButton;
