import { useEffect, useState } from "react";
import { ZENDESK_KEY } from "src/data/constants";
import useLang from "src/services/language/useLang";
import preloadImages from "src/utils/images/preloadImages";
import { SocialMessenger, ZendeskButtonProps, ZendeskWidgetProps } from "../Messengers.types";
import requestGetSocialMessengers from "../requestGetSocialMessengers";
import { ListOfMessengersProps, MessengersContextValue } from "./MessengersContext";

export const useMessengersContextLogic = (): MessengersContextValue => {
  const [socials, setSocials] = useState<SocialMessenger[]>([]);
  const [socialsAreReady, setSocialsAreReady] = useState(false);
  const [listOfMessengersIsOpen, setListOfMessengersIsOpen] = useState(false);
  const [zendeskIsOpen, setZendeskIsOpen] = useState(false);

  const language = useLang();

  // getting social messengers
  useEffect(() => {
    (async () => {
      const response = await requestGetSocialMessengers(language);

      if (response.status === 'success') {
        await preloadImages(response.data.map(m => m.img_url));
        setSocials(response.data);
      }

      setSocialsAreReady(true);
    })();
  }, [language]);

  const closeListOfMessengers = () => {
    setListOfMessengersIsOpen(false);
  };

  const handleCloseZendeskChat = () => {
    setZendeskIsOpen(false);
  };

  const handleClickZendeskChat = () => {
    setZendeskIsOpen(true);
    closeListOfMessengers();
  };

  const handleClickLauncherButton = () => {
    setListOfMessengersIsOpen(true);
  };

  const zendeskWidgetProps: ZendeskWidgetProps = {
    zendeskKey: ZENDESK_KEY as string,
    language,
    chatIsOpen: zendeskIsOpen,
    onCloseChat: handleCloseZendeskChat,
  };

  const zendeskButtonProps: ZendeskButtonProps = {
    onClick: handleClickZendeskChat,
  };

  const listOfMessengersProps: ListOfMessengersProps = {
    isOpen: listOfMessengersIsOpen,
    close: closeListOfMessengers,
    open: handleClickLauncherButton,
  };

  return {
    socials,
    socialsAreReady,
    zendeskWidgetProps: ZENDESK_KEY ? zendeskWidgetProps : undefined,
    zendeskButtonProps: ZENDESK_KEY ? zendeskButtonProps : undefined,
    listOfMessengersProps,
  };
};

export default useMessengersContextLogic;
