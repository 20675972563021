import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const InteractWatcherContext = createContext<boolean | null>(null);

export const useInteractWatcherContext = () => {
  const userInteracted = useContext(InteractWatcherContext);

  if (userInteracted === null) {
    throw new Error('InteractWatcherContext is used outside of provider');
  }

  return userInteracted;
};

export const InteractWatcherProvider = ({ children }: { children: ReactNode }) => {
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    function stopListening() {
      document.removeEventListener('click', handleInteract);
      document.removeEventListener('touchstart', handleInteract);
      document.removeEventListener('keydown', handleInteract);
      document.removeEventListener('mousemove', handleInteract);
    }

    function handleInteract() {
      setUserInteracted(true);
      stopListening();
    }

    document.addEventListener('click', handleInteract);
    document.addEventListener('touchstart', handleInteract);
    document.addEventListener('keydown', handleInteract);
    document.addEventListener('mousemove', handleInteract);

    return stopListening;
  }, []);

  return (
    <InteractWatcherContext.Provider value={userInteracted}>
      { children }
    </InteractWatcherContext.Provider>
  );
};
