import { ReactNode } from 'react';
import MessengersContext from './MessengersContext';
import useMessengersContextLogic from './useMessengersContextLogic';

export interface Props {
  children: ReactNode;
}

export default function MessengersContextProvider(props: Props) {
  const { children } = props;

  const ctxValue = useMessengersContextLogic();

  return (
    <MessengersContext.Provider value={ctxValue}>
      {children}
    </MessengersContext.Provider>
  );
}
