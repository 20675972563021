const preloadImages = async (imgs: string[]): Promise<void> => {
  const imagesPromise = imgs.map(img => new Promise((resolve) => {
    const el = document.createElement('img');
    el.src = img;
    el.onload = () => {
      resolve(true);
    };
  }));

  await Promise.all(imagesPromise);
};

export default preloadImages;
