import Cookies from 'js-cookie';
import { ParsedUrlQuery } from 'querystring';

export const updateAffiliateProgramsCookie = (
  query: ParsedUrlQuery,
  host?: string,
) => {
  if (host) {
    const topHost = host.split(':')[0].split('.').slice(-2).join('.');

    if (query?.admitad_uid) {
      setAdmitadCookie(String(query.admitad_uid), topHost);
    }

    if (query?.aff_sub) {
      setSalesDoublerCookie(String(query.aff_sub), topHost);
    }

    const isMobilePromo = !!query?.af_adset_id;
    setMobilePromoCookie(String(isMobilePromo), topHost);
  }
};

const ADMITAD_COOKIE_NAME = 'admitad_uid';
const SALESDOUBLER_COOKIE_NAME = 'sd_uid';
const MOBILE_PROMO_COOKIE_NAME = 'mobile_app_offer';

const setMobilePromoCookie = (value: string, host: string) => {
  Cookies.set(
    MOBILE_PROMO_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const setAdmitadCookie = (value: string, host: string) => {
  Cookies.set(
    ADMITAD_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};

const setSalesDoublerCookie = (value: string, host: string) => {
  Cookies.set(
    SALESDOUBLER_COOKIE_NAME,
    value,
    {
      expires: 30,
      domain: `.${host}`,
    },
  );
};
